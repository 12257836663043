import { zodResolver } from '@hookform/resolvers/zod'
import {
  extractAddressFromClientShippingAddress,
  normalizePostalCode,
  ShippingAddressInfo,
  validatePostalCode,
} from '@maru44/huntre-utils/src/models/clientShippingAddress'
import { DeviceOrderInput, TrialDays } from '@maru44/huntre-utils/src/models/deviceOrder'
import { DeviceType } from '@maru44/huntre-utils/src/models/deviceType'
import { Box, Button, CircularProgress, InputLabel, MenuItem, Paper, Select, Stack, Switch, TextField, Typography } from '@mui/material'
import { FormEvent, useCallback, useContext, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { AuthContext } from 'src/components/providers/AuthProvider'
import { ClientContext } from 'src/components/providers/ClientProvider'
import { SimPlanWithPrice } from 'src/components/providers/GlobalProductProvider'
import { useHttpsCallable } from 'src/utils/firebase'
import { capture } from 'src/utils/sentry'
import Stripe from 'stripe'
import { z } from 'zod'

type Props = {
  deviceType: DeviceType
  price: Stripe.Price
  simPlans?: SimPlanWithPrice[]
  onClose: () => void
}

type OrderResponse = {
  //   clientSecret: string
  deviceOrderId: string
  stripeInvoiceId: string
  stripeInvoiceUrl: string
}

const schema = (max?: number) =>
  z.object({
    count: max ? z.number().min(1, '1以上の数を入力してください').max(max, '在庫数を上回っています') : z.number().min(0),
    simPlanId: z.string(),
    // card, customer_balance
    paymentMethod: z.string(),
    clientShippingAddressId: z.string(),
    postalCode: z.string(),
    address: z.string(),
  })
export type FormState = z.infer<ReturnType<typeof schema>>

// TODO requiredSImならsimPlanIdが正しく入っていることを確認する
export const PurchaseArea = ({ deviceType, price, simPlans, onClose }: Props) => {
  const { client, clientShippingAddresses } = useContext(ClientContext)
  const [user] = useContext(AuthContext)

  // 既存のがなければ新規フォームをデフォで出す
  const [isNewAddress, setIsNewAddress] = useState<boolean>(clientShippingAddresses.length === 0)

  const defaultValues = useMemo((): FormState => {
    return {
      count: 1,
      simPlanId: simPlans?.find((v) => v.isDefault)?.id ?? '',
      clientShippingAddressId: clientShippingAddresses.find((v) => v.isDefault)?.id ?? '',
      postalCode: '',
      address: '',
      paymentMethod: 'card',
    }
  }, [simPlans, clientShippingAddresses])
  const { control, reset, handleSubmit, formState, watch, setValue } = useForm<FormState>({
    resolver: zodResolver(schema(deviceType.stock)),
    defaultValues,
    mode: 'all',
  })
  const current = watch()

  const isValid = useMemo(() => {
    return formState.isValid && (!deviceType.requiredSim || (current.simPlanId !== '' && !!simPlans?.find((v) => v.id === current.simPlanId)))
  }, [formState.isValid, current])

  const { totalPrice, totalAfter } = useMemo(() => {
    if (!price.unit_amount) {
      return {}
    }
    const simPlan = simPlans?.find((v) => v.id === current.simPlanId)

    let totalPrice =
      (price.unit_amount + (simPlan?.price?.unit_amount ?? 0)) * current.count +
      // 送料
      1000
    let totalAfter = undefined // 後日請求

    // NOTE: カード支払いの場合無料期間が設けてあるので simPlanの値段をtotalAfterに寄せる
    if (current.paymentMethod === 'card') {
      totalPrice =
        price.unit_amount * current.count +
        // 送料
        1000
      totalAfter = (simPlan?.price?.unit_amount ?? 0) * current.count
    }

    return {
      totalPrice,
      totalAfter,
    }
  }, [price, simPlans, current])

  // createInvoiceから帰ってきた注文と請求に関する情報
  const [order, setOrder] = useState<OrderResponse>()

  const handleClose = useCallback(() => {
    reset()
    onClose()
    setOrder(undefined)
  }, [reset, onClose, setOrder])

  const [call, isCalling, callError] = useHttpsCallable<{ type: string; order: DeviceOrderInput }, OrderResponse>('createInvoice')
  const onSubmit = useCallback(
    (e: FormEvent) => {
      try {
        e.preventDefault()
        handleSubmit(async (v) => {
          let shippingAddress: ShippingAddressInfo = {
            postalCode: normalizePostalCode(v.postalCode),
            address: v.address,
          }
          if (v.clientShippingAddressId !== '') {
            const selectedAddress = clientShippingAddresses.find((s) => s.id === v.clientShippingAddressId)
            if (!selectedAddress) {
              return
            }
            shippingAddress = extractAddressFromClientShippingAddress(selectedAddress)
          }
          if (!validatePostalCode(shippingAddress.postalCode)) {
            toast.error('郵便番号が不正です')
            return
          }

          const order: DeviceOrderInput = {
            clientId: client?.id ?? '',
            deviceTypeId: deviceType.id,
            simPlanId: v.simPlanId,
            count: v.count,
            clientShippingAddressId: v.clientShippingAddressId || null,
            shippingAddress,
          }

          const input = {
            type: 'device',
            order,
            paymentMethod: v.paymentMethod,
          }
          const response = await call(input)
          setOrder(response?.data)
        })()
      } catch (e) {
        capture(e)
        toast.error('Stripeでエラーがあり、決済に進めませんでした。時間を置いてからもう一度お試しください')
      }
    },
    [client, user, handleSubmit, call, setOrder]
  )

  const handleSwitchAddressForm = useCallback(() => {
    // 既存から新規に変える場合 clientShippingAddressIdを空にする
    if (!isNewAddress) {
      setValue('clientShippingAddressId', '')
    } else {
      setValue('clientShippingAddressId', clientShippingAddresses.find((v) => v.isDefault)?.id ?? '')
    }

    setIsNewAddress((v) => !v)
  }, [isNewAddress, clientShippingAddresses, setIsNewAddress, setValue])

  return (
    <Paper elevation={6} sx={{ p: 4 }}>
      <Stack component="form" spacing={2} onSubmit={onSubmit}>
        <Typography variant="h6">{price.nickname}の購入</Typography>
        <Controller
          name="count"
          control={control}
          render={({ field }) => (
            <>
              <InputLabel>個数</InputLabel>
              <TextField
                type="number"
                {...field}
                onChange={(e) => {
                  setValue('count', parseInt(e.currentTarget.value))
                  setOrder(undefined)
                }}
                error={!!formState.errors?.count}
                helperText={formState.errors.count?.message}
              />
            </>
          )}
        />
        {deviceType.requiredSim && (
          <Controller
            name="simPlanId"
            control={control}
            defaultValue={simPlans?.find((v) => v.isDefault)?.id ?? ''}
            render={({ field }) => (
              <>
                <InputLabel>通信プラン</InputLabel>
                <Select
                  fullWidth
                  {...field}
                  error={!!formState.errors?.simPlanId}
                  onChange={(e) => {
                    setValue('simPlanId', e.target.value)
                    setOrder(undefined)
                  }}
                >
                  {simPlans
                    ?.sort((a, b) => a.order - b.order)
                    .map((v) => (
                      <MenuItem value={v.id} key={v.id}>
                        {v.displayName}
                      </MenuItem>
                    ))}
                </Select>
              </>
            )}
          />
        )}
        <Controller
          name="paymentMethod"
          control={control}
          defaultValue="card"
          render={({ field }) => (
            <>
              <InputLabel>お支払い方法</InputLabel>
              <Select
                fullWidth
                {...field}
                error={!!formState.errors?.paymentMethod}
                onChange={(e) => {
                  setValue('paymentMethod', e.target.value)
                  setOrder(undefined)
                }}
              >
                <MenuItem value="card">カード</MenuItem>
                <MenuItem value="customer_balance">口座振替</MenuItem>
              </Select>
            </>
          )}
        />
        <InputLabel>お届け先</InputLabel>
        <Paper sx={{ p: 2 }}>
          <Stack spacing={2}>
            <Box display="flex" alignItems="center">
              <Typography>既存のお届け先で注文</Typography>
              <Switch checked={isNewAddress} onChange={handleSwitchAddressForm} disabled={clientShippingAddresses.length === 0} />
              <Typography>新規お届け先で注文</Typography>
            </Box>
            {isNewAddress ? (
              <>
                <Controller
                  name="postalCode"
                  control={control}
                  render={({ field }) => (
                    <>
                      <InputLabel>郵便番号</InputLabel>
                      <TextField
                        type="text"
                        required
                        {...field}
                        error={!!formState.errors?.postalCode}
                        helperText={formState.errors?.postalCode?.message}
                        onChange={(e) => {
                          setValue('postalCode', e.target.value)
                          setOrder(undefined)
                        }}
                      />
                    </>
                  )}
                />
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <>
                      <InputLabel>住所</InputLabel>
                      <TextField
                        type="text"
                        {...field}
                        error={!!formState.errors?.address}
                        helperText={formState.errors?.address?.message}
                        onChange={(e) => {
                          setValue('address', e.target.value)
                          setOrder(undefined)
                        }}
                      />
                    </>
                  )}
                />
              </>
            ) : (
              <Controller
                name="clientShippingAddressId"
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      fullWidth
                      {...field}
                      error={!!formState.errors?.clientShippingAddressId}
                      onChange={(e) => {
                        setValue('clientShippingAddressId', e.target.value)
                        setOrder(undefined)
                      }}
                    >
                      {clientShippingAddresses.map((v) => (
                        <MenuItem value={v.id} key={v.id}>
                          {v.postalCode} | {v.address}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
              />
            )}
          </Stack>
        </Paper>
        <Box>
          <Typography>
            <b>合計金額: {totalPrice?.toLocaleString('en-US')}円</b>
          </Typography>
          {totalAfter && (
            <Typography mt={2}>
              通信料におきましては、お手元に届くまでの時間を考慮して{TrialDays}日間の無料期間をご用意してます。{TrialDays}日後に{totalAfter}
              円の請求が今回ご使用いただいたカードに請求されます。
            </Typography>
          )}
        </Box>
        <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
          <Button variant="outlined" onClick={handleClose}>
            キャンセル
          </Button>
          {isCalling ? (
            <Box display="flex" alignItems="center" justifyContent="center" m={2}>
              <CircularProgress />
            </Box>
          ) : order ? (
            <a style={{ display: 'contained' }} href={order.stripeInvoiceUrl} target="_blank" rel="noopener noreferrer">
              Stripeでのお支払いに進む
            </a>
          ) : (
            <Button variant="contained" type="submit" disabled={!isValid || formState.isSubmitting || isCalling}>
              購入に進む
            </Button>
          )}
        </Box>
      </Stack>
    </Paper>
  )
}
