import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { baseURL, isStaging, serviceName } from 'src/config'

// TODO Helmet
export const Tokai0001 = () => {
  return (
    <>
      <Helmet>
        {isStaging && <meta name="robots" content="noindex" />}
        <meta charSet="utf-8" />
        <title>{isStaging ? `${serviceName}(stg) | IoT罠(LTEモデル)のご紹介` : `${serviceName} | IoT罠(LTEモデル)のご紹介`}</title>
        <meta property="og:type" content="product" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content={`${serviceName} | IoT罠(LTEモデル)のご紹介 低価格・使いやすい・単三電池で長時間稼働と3拍子揃ったモデルです 電波はLTE-M回線(スマホ)等と同じを利用します`}
        />
        <meta
          property="description"
          content={`${serviceName} | IoT罠(LTEモデル)のご紹介 低価格・使いやすい・単三電池で長時間稼働と3拍子揃ったモデルです 電波はLTE-M回線(スマホ)等と同じを利用します`}
        />
        <meta property="og:url" content={`${baseURL}/deviceTypes/lte-model-0001`} />
        {/* SNS */}
        {/* TODO create icon */}
        <meta property="og:image" content={`${baseURL}/icon.png`} />
        {/* TODO end of create icon */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={serviceName} />
        <meta
          name="twitter:description"
          content={`${serviceName} | IoT罠(LTEモデル)のご紹介 低価格・使いやすい・単三電池で長時間稼働と3拍子揃ったモデルです 電波はLTE-M回線(スマホ)等と同じを利用します`}
        />
        <meta name="twitter:image" content={`${baseURL}/icon.png`} />
      </Helmet>
      <Stack spacing={2}>
        <Typography variant="h5">LTEモデル - 商品紹介ページ</Typography>
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: (theme) => theme.palette.grey[300] }}>
          <img src="/device_image(lte_dev).png" height="280px" alt="デバイスのイメージ" />
        </Box>
        <Typography variant="h6">機能</Typography>
        <Typography>
          くくり罠や箱罠にデバイスを併設することでご利用いただけます。
          <br />
          LTE-M回線(スマートフォン)等と同じ仕組みでインターネットに接続します。圏外ではご使用いただけないため、比較的人里に近い場所での利用を想定しています。
          <br />
          利用可能エリアは
          <Link to="https://www.docomo.ne.jp/area/servicearea/?rgcd=03&cmcd=LTE" target="_blank">
            こちら
          </Link>
          よりご確認ください。
          <br />
          <br />
          通知は起動、罠の作動、故障や電池切れの可能性の検知の3種類があります。
          <br />
          現在通知方法はメールと管理画面への通知のみですが、今後LINEやSMS等にも対応していく予定です。
          <br />
          必要最低限の機能のみ実装しているため、使いやすくお求め易い価格となっております。
          <br />
          <br />
          動力は単3電池4つで1ヶ月稼働します (気候や電波の状態で上下します)。
        </Typography>
        <Typography variant="h6">料金</Typography>
        <Typography>デバイス本体価格 1,6000円</Typography>
        <Typography>通信費は3つのプランを用意しております。</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>プラン</TableCell>
                <TableCell>料金</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>猟期(3ヶ月)プラン</TableCell>
                <TableCell>3,000円</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>6ヶ月プラン</TableCell>
                <TableCell>4,200円</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>1年プラン</TableCell>
                <TableCell>6,000円</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {/* TODO link to 申し込みページ */}
      </Stack>
    </>
  )
}
